export const DORCHA_EMAIL = "dorchamusic@gmail.com"
export const BEN_BOT_URL = "https://github.com/mrbenbot"
export const BETH_URL = "https://artistsonthe.wixsite.com/bbellis"
export const HONEY_BADGER_URL =
  process.env.HONEY_BADGER_URL || "https://honey-badger.netlify.app/"

export const ALBUM_VIDEO_URL = "https://youtu.be/9MW7Y3IVTyQ"
export const isVideoReady = false
export const links = [
  {
    alt: "facebook logo",
    src: `FACEBOOK.png`,
    href: "https://www.facebook.com/Dorchauk/",
  },
  {
    alt: "instagram logo",
    src: `INSTA.png`,
    href: "https://www.instagram.com/dorcha_music/?hl=en",
  },
  // {
  //   alt: "bandcamp logo",
  //   src: `BANDCAMP.png`,
  //   href: "https://dorcha.bandcamp.com/",
  // },
]
